<template>
  <Delegate />
  <Rewards />
  <DelegationsList class="mt-5" />
</template>

<script>
import { defineComponent } from "vue";
import Delegate from "@/components/delegation/Delegate.vue";
import Rewards from "@/components/delegation/Rewards.vue";
import DelegationsList from "@/components/delegation/DelegationsList.vue"


export default defineComponent({
  components: {
    Delegate,
    Rewards,
    DelegationsList
  },
  mounted: function () {
    this.$store.commit("updateTopBarTitle", this.$i18n.t("menu.delegation"));
  },
});
</script>
