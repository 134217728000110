<template>
  <div class="grid grid-cols-12 gap-6 px-5 py-3 items-center relative">
    <!-- <LoadingBlock v-if="processing" /> -->

    <div class="col-span-12 xl:col-span-6">
      <div class="xl:hidden text-gray-500 text-sm mb-3">
        {{ $t("views.delegation.list.validator") }}:
      </div>

      <div class="flex items-center">
        <div class="mr-3 p-2">
          <button><CopyIcon /></button>
        </div>
        <div class="">
          <div>
            <h2>{{ delegation.validator.moniker }}</h2>
          </div>
          <div>
            <a
              class="hover:underline text-nowrap break-all"
              :href="`${blockExplorer}/validator/${delegation.validator.address}`"
              target="_blank"
              >{{ delegation.validator.address }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-12 xl:col-span-2 xl:text-right">
      <div class="xl:hidden text-gray-500 text-sm mb-3">
        {{ $t("views.delegation.list.amount") }}:
      </div>
      <div class="uppercase">
        {{ delegation.amount.amount }} {{ delegation.amount.denom }}
      </div>
    </div>

    <div class="col-span-12 xl:col-span-4">
      <div class="">
        <button
          v-if="!formRedelegateVisible && delegation.amount.amount > 0"
          v-on:click="formRedelegateToggle"
          class="btn btn-primary"
        >
          <RepeatIcon class="w-4 h-4 mr-2" />
          {{ $t("views.delegation.list.redelegate") }}
        </button>

        <button
          v-if="!formUnbondVisible && delegation.amount.amount > 0"
          v-on:click="formUnbondToggle"
          class="btn btn-primary ml-2"
        >
          <CornerRightUpIcon class="w-4 h-4 mr-2" />
          {{ $t("views.delegation.list.unbond") }}
        </button>
      </div>
    </div>

    <div class="col-span-12 xl:col-span-4" v-if="formRedelegateVisible">
      <div class="">
        <div class="">
          <label for="form-input-3" class="form-label">{{
            $t("views.delegation.list.validator")
          }}</label>
          <div class="relative">
            <input
              id="form-input-3"
              type="text"
              class="form-control form-control-lg text-lg"
              v-model="redelegate.validator"
            />
          </div>
        </div>
        <div class="mt-3">
          <label for="form-input-3" class="form-label">{{
            $t("views.delegation.list.amount")
          }}</label>
          <div class="relative">
            <input
              id="form-input-3"
              type="text"
              class="form-control form-control-lg text-lg"
              placeholder="0"
              v-model="redelegate.amount"
            />
            <div class="text-white absolute right-5 top-2 text-lg uppercase">
              {{ redelegate.denom }}
            </div>
          </div>
        </div>
        <div class="mt-3 flex">
          <button class="btn btn-primary" @click="processRedelegate">
            {{ $t("views.delegation.list.redelegate") }}
          </button>
          <button class="btn btn-secondary ml-2" @click="formRedelegateToggle">
            <XIcon />
          </button>
        </div>
      </div>
    </div>

    <div class="col-span-12" v-if="formUnbondVisible">
      <div class="">
        <label for="form-input-3" class="form-label">{{
          $t("views.delegation.list.amount")
        }}</label>
        <div class="flex">
          <div class="relative">
            <input
              id="form-input-3"
              type="text"
              class="form-control form-control-lg text-lg"
              placeholder="0"
              v-model="unbond.amount"
            />
            <div class="text-white absolute right-5 top-2 text-lg uppercase">
              {{ unbond.denom }}
            </div>
          </div>
          <button class="btn btn-primary ml-2" @click="processUnbond">
            {{ $t("views.delegation.list.unbond") }}
          </button>
          <button class="btn btn-secondary ml-2" @click="formUnbondToggle">
            <XIcon />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Constants from "@/utils/constants";
import Coin from "@/utils/coin";
import BigNumber from "bignumber.js";
import LoadingBlock from "@/components/common/LoadingBlock.vue";

/*
export interface MsgBeginRedelegate {
  delegatorAddress: string
  validatorSrcAddress: string
  validatorDstAddress: string
  amount: Coin | undefined
}
*/

/*
export interface MsgUndelegate {
  delegatorAddress: string
  validatorAddress: string
  amount: Coin | undefined
}
*/

export default defineComponent({
  name: "DelegationItem",
  components: {
    LoadingBlock,
  },
  props: {
    index: Number,
    delegation: Object,
    address: String,
  },
  emits: ["submit"],
  data: function () {
    return {
      redelegate: {
        validator: "",
        amount: "",
        denom: Constants.DEFAULT_DISPLAY_CURRENCY,
      },
      unbond: {
        amount: "",
        denom: Constants.DEFAULT_DISPLAY_CURRENCY,
      },
      blockExplorer: Constants.BLOCK_EXPLORER,
      formRedelegateVisible: false,
      formUnbondVisible: false,
      processing: false,
    };
  },
  methods: {
    copyValidator: async function () {
      await navigator.clipboard.writeText(this.delegation.validator.address);
      this.$toast.success(this.$i18n.t("common.copied"));
    },
    formRedelegateToggle: function () {
      this.formRedelegateVisible = !this.formRedelegateVisible;
    },
    formUnbondToggle: function () {
      this.formUnbondVisible = !this.formUnbondVisible;
    },
    processRedelegate: async function () {
      this.processing = true;
      try {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        const value = {
          delegatorAddress: this.address,
          validatorSrcAddress: this.delegation.validator.address,
          validatorDstAddress: this.redelegate.validator,
          amount: coin.toBase(this.redelegate.amount),
        };
        const simulateResult = await this.$store.dispatch(
          "cosmos.staking.v1beta1/sendMsgBeginRedelegateSimulate",
          {
            value,
            fee: [{ amount: "0", denom: Constants.DEFAULT_CURRENCY }],
            memo: "",
          }
        );
        let baseAmount = BigNumber(simulateResult.calculatedGas)
          .multipliedBy(BigNumber(Constants.GAS_PRICE))
          .multipliedBy(BigNumber(10).exponentiatedBy(coin.denomExponent()));
        baseAmount = baseAmount.integerValue(BigNumber.ROUND_CEIL);
        const fee = coin.toDisplay(baseAmount);

        const txResult = await this.$store.dispatch(
          "cosmos.staking.v1beta1/sendMsgBeginRedelegate",
          {
            value,
            fee: [coin.toBase(fee.amount)],
            memo: "",
            gas: simulateResult.calculatedGas.toString(),
          }
        );
        if (txResult && !txResult.code) {
          this.$toast.success(
            this.$i18n.t("views.delegation.notifications.successfulRedelegated")
          );
          this.$emit("submit");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.formRedelegateVisible = false;
        this.redelegate.amount = "";
        this.redelegate.validator = "";
        this.processing = false;
      }
    },
    processUnbond: async function () {
      this.processing = true;
      try {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        const value = {
          delegatorAddress: this.address,
          validatorAddress: this.delegation.validator.address,
          amount: coin.toBase(this.unbond.amount),
        };
        const simulateResult = await this.$store.dispatch(
          "cosmos.staking.v1beta1/sendMsgUndelegateSimulate",
          {
            value,
            fee: [{ amount: "0", denom: Constants.DEFAULT_CURRENCY }],
            memo: "",
          }
        );
        let baseAmount = BigNumber(simulateResult.calculatedGas)
          .multipliedBy(BigNumber(Constants.GAS_PRICE))
          .multipliedBy(BigNumber(10).exponentiatedBy(coin.denomExponent()));
        baseAmount = baseAmount.integerValue(BigNumber.ROUND_CEIL);
        const fee = coin.toDisplay(baseAmount);

        const txResult = await this.$store.dispatch(
          "cosmos.staking.v1beta1/sendMsgUndelegate",
          {
            value,
            fee: [coin.toBase(fee.amount)],
            memo: "",
            gas: simulateResult.calculatedGas.toString(),
          }
        );
        if (txResult && !txResult.code) {
          this.$toast.success(
            this.$i18n.t("views.delegation.notifications.successfulUnbonded")
          );
          this.$emit("submit");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.formUnbondVisible = false;
        this.unbond.amount = "";
        this.processing = false;
      }
    },
  },
});
</script>
