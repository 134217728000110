<template>
  <div class="intro-y box w-full xl:w-1/2 mt-5">
    <div class="box h-full intro-x">
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          p-5
          border-b border-gray-200
          dark:border-dark-5
        "
      >
        <h2 class="font-medium text-base mr-auto uppercase">{{ $t('views.delegation.reward.title') }}</h2>
      </div>

      <div class="p-5 flex flex-wrap gap-3 relative">
        <LoadingBlock v-if="processing" />
        <div class="mr-auto">
          <div
            class="relative text-2xl font-medium leading-5 mt-1 mr-3"
          >
            {{ rewardAmount }}
          </div>
          <div class="flex mt-2">
            <div class="mr-1">{{ $t('views.delegation.reward.fee') }}:</div>
            <div class="font-semibold uppercase">
              {{ fee.amount }} {{ fee.denom }}
            </div>
          </div>
        </div>
        <button class="btn btn-primary w-24" v-on:click="claimReward">{{ $t('views.delegation.reward.claim') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BigNumber from "bignumber.js";
import Constants from "@/utils/constants";
import Coin from "@/utils/coin";
import LoadingBlock from "@/components/common/LoadingBlock.vue";


export default defineComponent({
  name: "Rewards",
  components: {
    LoadingBlock
  },
  data: function () {
    return {
      address: "",
      gas: 0,
      fee: { amount: "0", denom: Constants.DEFAULT_DISPLAY_CURRENCY },
      processing: false,
    };
  },
  computed: {
    rewardAmount: function () {
      const rewards =
        this.$store.getters[
          "cosmos.distribution.v1beta1/getDelegationTotalRewards"
        ]({
          params: { delegator_address: this.address },
        })?.total ?? [];
      if (rewards.length > 0) {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        let reward = coin.toDisplay(rewards[0].amount);
        return `${
          reward.amount
        } ${Constants.DEFAULT_DISPLAY_CURRENCY.toUpperCase()}`;
      }
      return `0 ${Constants.DEFAULT_DISPLAY_CURRENCY.toUpperCase()}`;
    },
    canReward: function () {
      let rewards =
        this.$store.getters[
          "cosmos.distribution.v1beta1/getDelegationTotalRewards"
        ]({
          params: { delegator_address: this.address },
        })?.total ?? [];
      return rewards.length > 0 && rewards[0].amount > 0;
    },
  },
  mounted: async function () {
    this.address = this.$store.getters["common/wallet/address"];
    await this.$store.dispatch(
      "cosmos.distribution.v1beta1/QueryDelegationTotalRewards",
      {
        params: { delegator_address: this.address },
        options: {},
      }
    );
    this.calculateFee();
  },
  methods: {
    claimReward: async function () {
      this.processing = true;
      const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
      let rewards =
        this.$store.getters[
          "cosmos.distribution.v1beta1/getDelegationTotalRewards"
        ]({
          params: { delegator_address: this.address },
        })?.rewards ?? [];
      let success = false
      for (let i in rewards) {
        let reward = rewards[i];
        let value = {
          validatorAddress: reward.validator_address,
          delegatorAddress: this.address,
        };
        try {
          const txResult = await this.$store.dispatch(
            "cosmos.distribution.v1beta1/sendMsgWithdrawDelegatorReward",
            {
              value,
              fee: [coin.toBase(this.fee.amount)],
              memo: "",
              gas: this.gas.toString(),
            }
          );
          success = txResult && !txResult.code
        } catch (e) {
          console.error(e);
        }
      }
      if (success) {
        this.$toast.success(
          this.$i18n.t("views.delegation.notificationSuccessReward")
        );
      }
      await this.$store.dispatch(
        "cosmos.distribution.v1beta1/QueryDelegationTotalRewards",
        {
          params: { delegator_address: this.address },
          options: {},
        }
      )
      this.calculateFee()
      this.processing = false      
    },
    calculateFee: async function () {
      const rewards =
        this.$store.getters[
          "cosmos.distribution.v1beta1/getDelegationTotalRewards"
        ]({
          params: { delegator_address: this.address },
        })?.rewards ?? [];
      this.fee = { amount: "0", denom: Constants.DEFAULT_DISPLAY_CURRENCY };
      if (rewards.length > 0) {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        let value = {
          validatorAddress: rewards[0].validator_address,
          delegatorAddress: this.address,
        };
        try {
          const simulateResult = await this.$store.dispatch(
            "cosmos.distribution.v1beta1/sendMsgWithdrawDelegatorRewardSimulate",
            {
              value,
              fee: [{ amount: "0", denom: Constants.DEFAULT_CURRENCY }],
              memo: "",
            }
          );
          this.gas = simulateResult.calculatedGas;

          let baseAmount = BigNumber(this.gas)
            .multipliedBy(BigNumber(Constants.GAS_PRICE))
            .multipliedBy(BigNumber(10).exponentiatedBy(coin.denomExponent()));
          baseAmount = baseAmount.integerValue(BigNumber.ROUND_CEIL);
          this.fee = coin.toDisplay(baseAmount);
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
});
</script>
