<template>
  <div class="col-span-12">
    <div class="intro-y box">
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          p-5
          border-b border-gray-200
          dark:border-dark-5
        "
      >
        <h2 class="font-medium text-base mr-auto">{{ $t('views.delegation.list.title') }}</h2>
      </div>

      <div class="hidden xl:grid grid-cols-12 gap-6 my-5 px-5">
        <div class="col-span-12 xl:col-span-6">
          {{ $t('views.delegation.list.validator') }}
        </div>
        <div class="col-span-12 xl:col-span-2 text-right">{{ $t('views.delegation.list.amount') }}</div>
        <!-- <div class="hidden xl:block col-span-12 xl:col-span-2">{{ $t('views.delegation.list.unbonding') }}</div> -->
        <!-- <div class="hidden xl:block col-span-12 xl:col-span-4"></div> -->
      </div>

      <DelegationItem
        v-for="(delegation, index) in delegations"
        v-bind:key="index"
        v-on:submit="updateDelegationInfo"
        :address="address"
        :index="index"
        :delegation="delegation"
      />

      <div class="text-right gap-6 my-5 px-5">
        <!-- {{ delegations }} -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Coin from "@/utils/coin";
import Constants from "@/utils/constants";
import DelegationItem from './DeligationItem.vue'
import LoadingBlock from "@/components/common/LoadingBlock.vue";


export default defineComponent({
    name: 'DeligationsList',
    components: {
      DelegationItem
    },
    data: function() {
        return {
            address: ''
        }
    },
    computed: {
        delegations: function() {
          const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
          const delegator = this.address
          const store = this.$store
          const delegatorDelegations = store.getters['cosmos.staking.v1beta1/getDelegatorDelegations']({
                params: { delegator_addr: delegator }
            }).delegation_responses
          return delegatorDelegations?.map((dd) => {
            const validatorInfo = store.getters['cosmos.staking.v1beta1/getDelegatorValidators']({
                params: { delegator_addr: delegator }
            }).validators?.find(function(v) {
              return v.operator_address == dd.delegation.validator_address 
            }) || {}
            return {
              validator: {
                address: dd.delegation.validator_address,
                moniker: validatorInfo?.description.moniker
              },
              amount: coin.toDisplay(dd.delegation.shares)
            }
          })
        }
    },
    mounted: async function() {
        this.address = this.$store.getters["common/wallet/address"]
        await this.updateDelegationInfo()
    },
    methods: {
      updateDelegationInfo: async function() {
        const delegatorDelegations = await this.$store.dispatch(
            'cosmos.staking.v1beta1/QueryDelegatorDelegations',
            {
                params: { delegator_addr: this.address },
                options: {},
            }
        )
        console.log('delegatorDelegations', delegatorDelegations)
        
        const delegatorUnbondingDelegations = await this.$store.dispatch(
            'cosmos.staking.v1beta1/QueryDelegatorUnbondingDelegations',
            {
                params: { delegator_addr: this.address },
                options: {},
            }
        )
        console.log('delegatorUnbondingDelegations', delegatorUnbondingDelegations)

        const delegatorValidators = await this.$store.dispatch(
            'cosmos.staking.v1beta1/QueryDelegatorValidators',
            {
                params: { delegator_addr: this.address },
                options: {},
            }
        )
        console.log('delegatorValidators', delegatorValidators)
      }
    }
})
</script>
