<template>
  <div class="intro-y box w-full xl:w-1/2 mt-5">
    <div
      class="
        flex flex-col
        sm:flex-row
        items-center
        p-5
        border-b border-gray-200
        dark:border-dark-5
      "
    >
      <h2 class="font-medium text-base mr-auto">{{ $t('views.delegation.delegate.title') }}</h2>
    </div>
    <div class="p-5 relative">
      <LoadingBlock v-if="processing" />
      <div>
        <label for="form-input-1" class="form-label upercase">{{ $t('views.delegation.delegate.delegateTo') }}</label>
        <input
          id="form-input-1"
          type="text"
          class="form-control"
          v-model="transfer.recipient"
          @update:modelValue="calculateFee"
        />
      </div>

      <div class="mt-3 relative">
        <label for="form-input-1" class="form-label">{{ $t('views.delegation.delegate.amount') }}</label>
        <input
          id="form-input-1"
          type="text"
          class="form-control form-control-lg text-lg"
          placeholder="0"
          v-model="transfer.amount"
          @update:modelValue="calculateFee"
          v-on:keypress="onlyFloat"
        />
        <div class="absolute right-5 top-9 text-lg uppercase">
          {{ transfer.fee.denom }}
        </div>
      </div>

      <div class="flex items-center mt-2">
        <div class="mr-1">{{ $t('views.delegation.delegate.availableBalance') }}:</div>
        <div class="font-semibold uppercase">
          {{ balance.amount }} {{ balance.denom }}
        </div>
      </div>

      <div class="mt-5 flex items-center">
        <div class="">
          <div class="flex">
            <div class="mr-1">{{ $t('views.delegation.delegate.fee') }}:</div>
            <div class="font-semibold uppercase">
              {{ transfer.fee.amount }} {{ transfer.fee.denom }}
            </div>
          </div>
        </div>
        <div class="ml-auto flex">
          <button class="btn btn-secondary w-24 mr-1 mb-2" v-on:click="reset">
            {{ $t('views.delegation.delegate.reset') }}
          </button>
          <button
            class="btn btn-primary w-24 mr-1 mb-2"
            v-on:click="delegate"
          >
            {{ $t('views.delegation.delegate.delegate') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Bech32 } from "@cosmjs/encoding";
import BigNumber from "bignumber.js";
import Constants from "@/utils/constants";
import Coin from "@/utils/coin";
import LoadingBlock from "@/components/common/LoadingBlock.vue";

const _ = require("lodash");

export default defineComponent({
  name: 'Delegate',
  components: {
    LoadingBlock
  },
  data() {
    return {
      transfer: {
        recipient: "",
        amount: "",
        gas: 0,
        fee: { amount: "0", denom: Constants.DEFAULT_DISPLAY_CURRENCY },
      },
      address: "",
      balance: { amount: "0", denom: Constants.DEFAULT_DISPLAY_CURRENCY },
      staking: {},
      processing: false,
    };
  },
  computed: {
    validAddress: function () {
      let toAddress;
      try {
        toAddress = !!Bech32.decode(this.transfer.recipient);
      } catch {
        toAddress = false;
      }
      return toAddress;
    },
    validForm: function () {
      return (
        this.validAmount &&
        this.validAmount(this.transfer.amount, true) &&
        this.validAmount(this.transfer.fee.amount, true)
      );
    },
  },
  mounted: async function () {
    this.address = this.$store.getters["common/wallet/address"];
    this.staking = this.$store.getters["cosmos.staking.v1beta1/getParams"]();
    this.reset();
  },
  methods: {
    validAmount: function (amount, gtz = false) {
      const res =
        !isNaN(amount) && !isNaN(parseFloat(amount)) && isFinite(amount);
      return gtz ? res && parseFloat(amount) > 0 : res;
    },
    onlyFloat(event, message) {
      if (!/\d/.test(event.key) && (event.key !== "." || /\./.test(message)))
        return event.preventDefault();
    },
    delegate: async function () {
      if (this.validForm && !this.processing) {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);

        const value = {
          amount: coin.toBase(this.transfer.amount),
          validatorAddress: this.transfer.recipient,
          delegatorAddress: this.address,
        };

        this.processing = true;

        try {
          const txResult = await this.$store.dispatch(
            "cosmos.staking.v1beta1/sendMsgDelegate",
            {
              value,
              fee: [coin.toBase(this.transfer.fee.amount)],
              memo: "",
              gas: this.transfer.gas.toString(),
            }
          );
          if (txResult && !txResult.code) {
            this.$toast.success(
              this.$i18n.t("views.delegation.notificationSuccessDelegate")
            );
            this.reset();
          }
        } catch (e) {
          console.error(e);
        } finally {
          this.processing = false;
        }
      }
    },
    calculateFee: _.debounce(async function ($value) {
      if (this.validAddress && this.validAmount(this.transfer.amount, true)) {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        const value = {
          amount: coin.toBase(this.transfer.amount),
          validatorAddress: this.transfer.recipient,
          delegatorAddress: this.address,
        };
        try {
          const simulateResult = await this.$store.dispatch(
            "cosmos.staking.v1beta1/sendMsgDelegateSimulate",
            {
              value,
              fee: [{ amount: "0", denom: Constants.DEFAULT_CURRENCY }],
              memo: "",
            }
          );
          this.transfer.gas = simulateResult.calculatedGas;

          let baseAmount = BigNumber(this.transfer.gas)
            .multipliedBy(BigNumber(Constants.GAS_PRICE))
            .multipliedBy(BigNumber(10).exponentiatedBy(coin.denomExponent()));
          baseAmount = baseAmount.integerValue(BigNumber.ROUND_CEIL);
          this.transfer.fee = coin.toDisplay(baseAmount);
        } catch (e) {
          console.error(e);
        }
      }
    }, 500),
    reset: async function () {
      this.$store
        .dispatch("cosmos.bank.v1beta1/QueryAllBalances", {
          params: { address: this.address },
          options: {},
        })
        .then((res) => {
          const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
          for (const b in res.balances) {
            if (res.balances[b].denom == Constants.DEFAULT_CURRENCY) {
              this.balance = coin.toDisplay(res.balances[b].amount);
            }
          }
        });
      this.transfer.amount = "";
      this.transfer.fee = {
        amount: "0",
        denom: Constants.DEFAULT_DISPLAY_CURRENCY,
      };
      this.transfer.gas = 0;
      this.transfer.recipient = "";
    },
  },
});
</script>
